import React, {useState} from "react";

// Style Import
import "./css/styles.scss";

// SVG Imports
import { ReactComponent as KMLogo } from "./images/KM.svg";
import { ReactComponent as EmailImage } from "./images/email.svg";
import { ReactComponent as EmailIcon } from './images/envelope-solid.svg';
import { ReactComponent as PlaneIcon } from './images/paper-plane-solid.svg';
import { ReactComponent as CheckIcon } from './images/check-solid.svg';
// import { ReactComponent as SpinnerIcon } from './images/spinner-solid.svg';
import { ReactComponent as LinkedIn } from './images/linkedin-in.svg';
import { ReactComponent as GitHub } from './images/github-alt.svg';
import { ReactComponent as Instagram } from './images/instagram.svg';

// Component Imports
import AnimatedCursor from "react-animated-cursor";
import Popup from 'reactjs-popup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";




const schema = yup.object({
  name: yup.string().required('Your Name is a required field'),
  email: yup.string().email('Please provide a valid email address').required('Email Address is a required field'),
  message: yup.string().required('Message is a required field')
}).required();

function App() {

  const { reset, register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [status,setStatus] = useState('');

  const encode = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((k)=>{
      formData.append(k,data[k]);
    });
    return formData;
  };

  function delay(t, v) {
    return new Promise(function(resolve) { 
        setTimeout(resolve.bind(null, v), t);
    });
  }

  function delayMessage(t, v) {
    return new Promise(function(resolve) { 
        setTimeout(resolve.bind(null, v), t);
    });
  }

  const onSubmit = data => {

    data = { "form-name": "contactForm", ...data };
    
    fetch("/", {
      method: "POST",
      // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
      body: encode(data)
    })
      .then(() => {

        return delay(2000).then(function() {
          setStatus("Form Submission Successful");
          reset({ name: '', email: '', botField: '', message: '' });

            return delayMessage(5000).then(function() {
              setStatus("");
            }
          );
      });
      })
      .catch(error => setStatus("Form Submission Failed!"));

  };

    return (
      <>
        <AnimatedCursor 
          innerSize={8}
          outerSize={8}
          color='230, 57, 70'
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
          trailingSpeed={15}
          hasBlendMode={true}
          outerStyle={{
            zIndex: '999999'
          }}
          innerStyle={{
            zIndex: '999999'
          }}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'select',
            'textarea',
            'button',
            '.button',
            '.email-button'
          ]}
        />

          <header className="main-holder">

            <div className="left">
              <h1><KMLogo className="km-logo" /></h1>
              <div className="intro">
                <h2>I'm Kristam Moffett-Culkin, a front-end developer &amp; designer based in Belfast.</h2>
                <p>I tend to wear many hats, and don't mind jumping into the unknown. Currently working on Web3 with <a href="https://moralis.io/" target="_blank" rel="noreferrer"><strong>Moralis.io</strong></a> as a front-end engineer and UI/UX designer. Please reach out if you have any questions!</p>
              </div>
            </div>

            <div className="right">
              <div className="social">
                <a className="email" href="mailto:hello@kristam-moffett.com" target="_blank" rel="noreferrer">hello@kristam-moffett.com</a>
                <a className="social-icon" href="https://www.linkedin.com/in/kristammoffett/" target="_blank" rel="noreferrer"><LinkedIn /></a>
                <a className="social-icon" href="https://github.com/KristamMoffett" target="_blank" rel="noreferrer"><GitHub /></a>
                <a className="social-icon" href="https://www.instagram.com/kristammoffett/" target="_blank" rel="noreferrer"><Instagram /></a>
              </div>

              
            </div>
            
          </header>


        <Popup trigger={
          <div className="email-button">
            <EmailIcon />
          </div>} role="dialog" modal>
          <div className="form-side">
            <EmailImage />
          </div>
          <form className="form" data-netlify-recaptcha="true" netlify-honeypot="botField" onSubmit={handleSubmit(onSubmit)}>

            <div className={errors.name ? 'form-input error' : 'form-input'}>
              <label htmlFor="name">Your Name</label>
              <input type="text" name="name" placeholder="e.g. John Doe"  {...register("name", { required: true })} />
              <p className="errorMessage">{errors.name?.message}</p>
            </div>

            <div className={errors.email ? 'form-input error' : 'form-input'}>
              <label htmlFor="name">Email Address</label>
              <input type="text" name="email" placeholder="e.g. john.doe@me.com"  {...register("email", { required: true })} />
              <p className="errorMessage">{errors.email?.message}</p>
            </div>

            <div className={errors.message ? 'form-input error' : 'form-input'}>
              <label htmlFor="name">Message</label>
              <textarea name="message" placeholder="Ask me anything!"  {...register("message", { required: true })} />
              <p className="errorMessage">{errors.message?.message}</p>
            </div>

            <div data-netlify-recaptcha="true">

            </div>

            <div className="hidden-input">
              <input name="botField" />
            </div>

            <button type="submit">
              <PlaneIcon />
              Send Message
            </button>


            {status ? <p className="successMessage">{status} <CheckIcon /></p> : ''}

          </form>
        </Popup>
      </>
    )
}

export default App
